import React from 'react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faArrowUp)

function FooterBottom() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <div className='footer-bottom-main pt-5 sm-mx:pt-3'>
        <div className='container mx-auto'>
          <div className='row justify-between sm-mx:flex-col-reverse '>
            <div className='w-6/12 md:w-8/12 sm-mx:w-full sm-mx:text-center'>
              <p className=''>Copyright © {new Date().getFullYear()} Ship and Storage. All Rights Reserved</p>
            </div>
            <div className='w-6/12 md:w-4/12 sm-mx:w-full row justify-end sm-mx:justify-center sm-mx:mb-2 sm-mx:mt-3'>
              <div className='w-5/12 lg-mx:w-6/12 md-mx:w-full sm-mx:w-full row md-mx:justify-end sm-mx:justify-center'>
                <ul className='footer-menu flex'>
                  <li className='pl-0'>
                    <Link className='' href='/terms-and-conditions'>
                      Terms of Use
                    </Link>
                  </li>
                  <li className='pl-4'>
                    <Link className='' href='/privacy-policy'>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='w-full md-mn:hidden row justify-center single-widget mb-2'>{/*<SocialIcons />*/}</div>
          </div>
        </div>
        <div className='scrollToTop pr-10 flex justify-end' onClick={scrollToTop}>
          <FontAwesomeIcon icon={faArrowUp} width={20} height={20} className={'cursor-pointer'} />
        </div>
      </div>
    </>
  )
}

export default FooterBottom
