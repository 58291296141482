import React from 'react'
import Header from '@/layouts/header'
import Footer from '@/layouts/footer'
import AuthCheck from '@/components/AuthCheck'
import ToasterInfoMessages from '@/components/common/ToasterInfoMessages'

const Layout = ({ children }) => {
  return (
    <>
      <ToasterInfoMessages />
      <AuthCheck>
        <Header />
      </AuthCheck>
      <div className={'sas-main-content-wr'}>{children}</div>
      <Footer />
    </>
  )
}

export default Layout
