import { server } from '@/configs/server'

const staleTime = process.env.NEXT_PUBLIC_STALE_TIME

const Configs = {
  xs: 480,
  sm: 768,
  md: 991,
  lg: 1199,
  apiBaseURL: server,
  appBaseNameURL: '/',
  appURL: process.env.NEXT_PUBLIC_APP_URL,
  APP_NAME: process.env.NEXT_PUBLIC_APP_NAME,
  assetsURL: process.env.NEXT_PUBLIC_APP_URL,
  contactNo: process.env.NEXT_PUBLIC_CONTACT_NO,
  supportEmail: process.env.NEXT_PUBLIC_SUPPORT_EMAIL,
  customerEmail: process.env.NEXT_PUBLIC_CUSTOMER_EMAIL,
  version: Date.now(), //process.env.ASSETS_VERSION,
  // apiBaseURL: process.env.NEXT_PUBLIC_API_PROD_URL,
  staleTime: staleTime ? Number(staleTime) : 1000 * 60 * 10
}
export default Configs
