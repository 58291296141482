import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import useResponsive from '@/hooks/responsive'
import Icon from '@/@core/components/icon'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { assets } from '@/helpers/common'
import { connect } from 'react-redux'
import * as actionTypes from '@/store/constants/actionTypes'
import AuthHeader from '@/layouts/header/AuthHeader'
import { cn } from '@/lib/utils'
import Menu from '@/components/subComponents/menu/Menu'
import SidebarMenu from '@/components/subComponents/menu/SidebarMenu'
import { Routes } from '@/v1/constants/routes'

const menu = [
  { title: 'Residential <br/>Storage Solution', icon: 'images/menu-icon-1.png', link: '/residential-storage' },
  { title: 'Business <br/>Storage Solution', icon: 'images/menu-icon-2.png', link: '/business-storage' },
  { title: 'Moving <br/>Storage Solution', icon: 'images/menu-icon-3.png', link: '/moving-storage-solution' },
  {
    title: 'Luggage & Box <br/>Shipping Services',
    icon: 'images/menu-icon-6.png',
    link: '/luggage-box-shipping-services'
  },
  { title: 'Get A Quote', icon: 'images/menu-icon-4.png', link: '/book-storage-facility', className: 'get-a-quote' },
  { title: 'About Us', icon: 'images/menu-icon-5.png', link: '/about-us', className: 'about-us' }
  // { title: 'Order Packing Material', icon: 'images/menu-icon-3.png', link: '/order-packing-material' },
  // {title: 'Book Now', icon: 'images/menu-icon-4.png', link: '/book-now'},
  // { title: 'About Us', icon: 'images/menu-icon-5.png', link: '/about-us' },
  // {title: 'Login', link: '/login', type: 'btn', mobile: false},
]

const Header = props => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const { xs, sm, md, lg, xl, xxl } = useResponsive()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 90
  })

  // change Menu Status
  const changeMenuStatus = () => setShowMobileMenu(!showMobileMenu)

  useEffect(() => {
    let bodyElement = document.querySelector('body')
    if (showMobileMenu) {
      bodyElement.style.overflow = 'hidden'
    } else {
      bodyElement.style.overflow = 'auto'
    }
  }, [showMobileMenu])

  const logout = async event => {
    event.preventDefault()
    await props.onLogout()
  }

  // New Requirement March 2024 from Mr. Mike
  if (props?.Auth_Reducer?.auth) {
    return <AuthHeader />
  }

  return (
    <>
      <header
        id='header-main'
        className={cn(
          `site-header sm:overflow-hidden flow-root fixed top-0 right-0 left-0 z-50 transition xs:duration-200`,
          props?.className,
          { 'bg-gray-100': trigger }
        )}
      >
        <div className='container mx-auto pt-5 pb-5 '>
          <nav className='navbar row w-full justify-between items-center'>
            <Link className='navebar-brand' href='/'>
              <img src={assets('images/logo.png')} alt='Ship And Storage' />
            </Link>
            <div className='mobile-buttons'>
              <a href='tel:1 888 906 3909' className='contect-number'>
                1 888 906 3909
              </a>
              <Link href='/book-storage-facility' className='get-a-quote'>
                Get A Quote
              </Link>
            </div>
            <span className='mobile-menu-arrow' onClick={changeMenuStatus}>
              {!showMobileMenu ? (
                <span className='open'>
                  <Icon icon='fa:bars' fontSize={22} />
                </span>
              ) : (
                <span className='close'>
                  <Icon icon='fa-solid:times' fontSize={22} />
                </span>
              )}
            </span>
            {xl || xxl ? <Menu menuItems={menu} Auth_Reducer={props.Auth_Reducer} logout={logout} /> : ''}
          </nav>
        </div>
      </header>

      {(xs || sm || md || lg) && (
        <SidebarMenu menuItems={menu} menuStatus={showMobileMenu} changeMenuStatus={changeMenuStatus}>
          <div className='menu-footer'>
            {props?.Auth_Reducer?.auth ? (
              <>
                <Link className='btn btn-primary' href={Routes.DASHBOARD}>
                  Dashboard
                </Link>
                <Link className='btn btn-primary' href={Routes.LOGIN} onClick={logout}>
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link className='btn btn-primary' href={Routes.LOGIN} onClick={changeMenuStatus}>
                  Login
                </Link>
                <Link className='btn btn-primary' href={Routes.REGISTER} onClick={changeMenuStatus}>
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </SidebarMenu>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    Auth_Reducer: state.authReducer
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: actionTypes.USER_LOGOUT })
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
