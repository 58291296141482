import React from 'react'
import { assets } from '@/helpers/common'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faCheck)

const SocialIcons = function (props) {
  let wrapperClass = 'social-icons'
  // let listIcon = !!props.listIcon
  wrapperClass = props.className ? wrapperClass + ' ' + props.className : wrapperClass
  // const titleKeyName = props.titleKeyName ? props.titleKeyName : 'title'

  if (props.layout) {
    wrapperClass += props.layout
  }

  return (
    <ul className={wrapperClass}>
      <li>
        <a href={process.env.SOCIAL_LINK_YOUTUBE} target='_blank'>
          <img src={assets('images/Youtube.png')} alt={''} />{' '}
        </a>
      </li>
      <li>
        <a href={process.env.SOCIAL_LINK_INSTRAGRAM} target='_blank'>
          <img src={assets('images/instragram.png')} alt={''} />{' '}
        </a>
      </li>
      <li>
        <a href={process.env.SOCIAL_LINK_FACEBOOK} target='_blank'>
          <img src={assets('images/facebook.png')} alt={''} />{' '}
        </a>
      </li>
      <li>
        <a href={process.env.SOCIAL_LINK_LINKEDIN} target='_blank'>
          <img src={assets('images/linkedin.png')} alt={''} />{' '}
        </a>
      </li>
    </ul>
  )
}

export default SocialIcons
