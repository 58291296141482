import React from 'react'
import Link from 'next/link'
import { motion } from 'framer-motion'

import { Routes } from '@/v1/constants/routes'
import PageLoader from '@/components/common/PageLoader'
import { library } from '@fortawesome/fontawesome-svg-core'
import MenuItem from '@/components/subComponents/menu/MenuItem'
import { faList, faTimes } from '@fortawesome/free-solid-svg-icons'

library.add(faList, faTimes)

const variants = {
  open: { opacity: 1, height: 'auto' },
  closed: { opacity: 0, height: 0 }
}

const Menu = function (props) {
  let menuItems = props.menuItems.map((item, key) => {
    return <MenuItem key={key} item={item} />
  })

  // get Auth Menu
  const getAuthItems = () => {
    // let loginOrLogOutBtn = props.Auth_Reducer.auth ? <button type="button" className="btn btn-primary" onClick={logout} >Logout</button> : <Link to="/login" className="active" className="btn btn-primary" >Login</Link>;

    let username = props.Auth_Reducer.authInfo.first_name

    let UserMenu = props.Auth_Reducer.auth ? (
      <div className='user-menu'>
        <button type='button' className='btn btn-primary capitalize'>
          {username}
        </button>
        <ul>
          <li>
            <Link href={Routes.DASHBOARD}>Dashboard</Link>
          </li>
          <li>
            <Link href={Routes.LOGIN} onClick={event => props.logout(event)}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
    ) : props.Auth_Reducer.checkAuth ? (
      <PageLoader />
    ) : (
      <>
        <div className={'flex gap-2 mb-1'}>
          <Link href={Routes.LOGIN} className='btn btn-primary login'>
            Login
          </Link>

          <Link href={Routes.REGISTER} className='btn btn-primary login'>
            Sign Up
          </Link>
        </div>
      </>
    )

    return (
      <li className='flex flex-col items-end'>
        {UserMenu}
        <Link href='tel:1 888 906 3909' className='contect-number'>
          1 888 906 3909
        </Link>
        {/* <Link to="contact-us" className="active"
                    // onClick={props.changeMenuStatus} 
                    className="contact-item"
                    >Contact Us</Link>  */}
      </li>
    )
  }

  return (
    <>
      {/* animate={props.getMenuStatus() ? "open" : "closed"} */}
      <motion.ul className='main-menu' animate='open' variants={variants}>
        {menuItems}
        {getAuthItems()}
      </motion.ul>
    </>
  )
}

// export default withRouter(Menu);
export default Menu
