import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'

library.add(faCheck)

const LinkList = function (props) {
  let wrapperClass = 'link-list'
  let linkDisable = !props.linkDisable
  let listIcon = !!props.listIcon
  wrapperClass = props.className ? wrapperClass + ' ' + props.className : wrapperClass
  const titleKeyName = props.titleKeyName ? props.titleKeyName : 'title'
  const list = props.listData.map((item, key) => {
    let content = linkDisable ? (
      <Link href={item.slug ? item.slug : '/'}>{item[titleKeyName]}</Link>
    ) : (
      item[titleKeyName]
    )
    let icons = listIcon ? (
      <span className='icon'>
        <FontAwesomeIcon icon={['fas', 'check']} />
      </span>
    ) : (
      ''
    )

    return (
      <li key={key}>
        {icons}
        {content}
      </li>
    )
  })

  if (props.layout) {
    wrapperClass += props.layout
  }

  return <ul className={wrapperClass}>{list}</ul>
}

export default LinkList
