import React from 'react'
import { assets } from '@/helpers/common'
import { Routes } from '@/v1/constants/routes'
import LinkList from '@/components/subComponents/linkList/LinkList'
import SocialIcons from '@/components/subComponents/socialIcons/SocialIcons'

const menu_one = [
  { title: 'Residential Storage Solution', slug: '/residential-storage' },
  { title: 'Business Storage Solution', slug: '/business-storage' },
  { title: 'Moving Storage Solution', slug: '/moving-storage-solution' },
  { title: 'Book Now', slug: '/book-storage-facility' },
  { title: 'Luggage & Box Shipping Services', slug: '/luggage-box-shipping-services' },
  { title: 'Order Packing Material', slug: '/order-packing-material' },
  { title: 'Prohibited Items', slug: Routes.PROHIBITED_ITEMS },
  { title: 'Shipping Tips', slug: Routes.SHIPPING_TIPS },
  { title: 'General FAQ', slug: Routes.FAQ },
  { title: 'About Us', slug: '/about-us' },
  { title: 'Contact Us', slug: '/contact' }
]
const menu_two = [
  { title: 'Luggage Storage', slug: '/luggage-storage' },
  { title: 'Box Storage', slug: '/box-storage' },
  { title: 'Golf Club Storage', slug: '/golf-club-storage' },
  { title: 'Ski Storage', slug: '/ski-storage' },
  { title: 'Snowboard Storage', slug: '/snowboard-storage' }
]

function FooterWidgets() {
  return (
    <>
      <div className='footer-widger-main block w-full'>
        <div className='container mx-auto'>
          <div className='row justify-between'>
            <div className='w-5/12 sm-mx:w-full'>
              <div className='single-widget'>
                <div className='text-center mb-10'>
                  <img src={assets('images/footer-logo.png')} alt={'ShipAndStorage'} />
                </div>
                <p className='md:pr-4'>
                  We brought smartest solution for ultimate storage and shipping. We have smart storage for your
                  personalized needs and worldwide shipping option across 220 countries with FedEx and DHL. So you can
                  store your items with us and ship anytime, anywhere!
                </p>
              </div>
              <div className='single-widget mt-4 sm-mx:hidden'>
                <SocialIcons />
              </div>
            </div>
            <div className='w-6/12 md-mx:w-7/12 sm-mx:w-full row justify-end sm-mx:justify-between pt-10'>
              <div className='w-6/12 sm-mx:w-6/12 row'>
                <div className='single-widget'>
                  <h4 className='title'>Quick Links</h4>
                  <LinkList listData={menu_one} />
                </div>
              </div>
              <div className='w-5/12 lg-mx:w-6/12 row md-mx:justify-end sm-mx:justify-end'>
                <div className='single-widget'>
                  <h4 className='title'>Storage Services</h4>
                  <LinkList listData={menu_two} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterWidgets
