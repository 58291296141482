import React from 'react'
import FooterWidgets from './FooterWidgets'
import FooterBottom from './FooterBottom'

function Footer() {
  return (
    <>
      <footer id='footer-main'>
        <div className='block w-full relative'>
          <FooterWidgets />
          <FooterBottom />
        </div>
      </footer>
    </>
  )
}

export default Footer
