import React from 'react'
import { motion } from 'framer-motion'
import MenuItem from './MenuItem'
import Icon from '@/@core/components/icon'

const variants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 85% 60px)`,
    display: 'block',
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: 'circle(0px at 85% 60px)',
    transition: {
      delay: 0,
      type: 'spring',
      stiffness: 400,
      damping: 40
    },
    transitionEnd: {
      display: 'none'
    }
  }
}

const SidebarMenu = function (props) {
  let menuItems = props.menuItems.map((item, key) => {
    if (item?.mobile !== false) {
      return <MenuItem key={key} item={item} changeMenuStatus={props.changeMenuStatus} icon={true} />
    }
  })

  // console.log({props})

  return (
    <motion.div
      className={props.menuStatus ? 'sidebar-menu menu-open' : 'sidebar-menu menu-closed'}
      animate={props?.menuStatus ? 'open' : 'closed'}
      variants={variants}
    >
      <div className='navbar'>
        <span className='mobile-menu-close' onClick={props.changeMenuStatus}>
          <span className='close'>
            <Icon icon='fa-solid:times' fontSize={24} />
          </span>
        </span>
        <ul className='main-menu'>{menuItems}</ul>
        {props.children}
      </div>
    </motion.div>
  )
}

export default SidebarMenu
